import useSWRMutation from 'swr/mutation';
import http from 'providers/HttpProvider';

const fetcher = (_, { arg }) => {
  return http.put(`/affiliates/${arg.id}`, arg);
};

export const useUpdateAffiliate = ({ onSuccess }) => {
  const { trigger, isMutating, error } = useSWRMutation(
    'update-affiliate',
    fetcher,
    { onSuccess }
  );
  return {
    trigger,
    isMutating,
    error,
  };
};
