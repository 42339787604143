import React, { useState, useImperativeHandle } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const theme = createTheme({
  palette: {
    primary: {
      main: '#eaeaea',
    },
  },
});

export function LoadingProgress() {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress color="primary" size={18} thickness={2} />
    </ThemeProvider>
  );
}

const GlobalLoading = React.forwardRef((_, ref) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <div className="flex flex-col items-center justify-center">
        <CircularProgress color="inherit" />
        <p>Vui lòng đợi...</p>
      </div>
    </Backdrop>
  );
});

export default GlobalLoading;
