import { useEffect, useMemo } from 'react';
import { SelectInput, useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import useAddress from 'hooks/useAddress';

const SelectAddress = ({
  label,
  source,
  optionValue,
  dependedSource = null,
  defaultValue = null,
  type,
  fullWidth = true,
  className = '',
  disabled = false,
  validate = null,
  helperText = true,
}) => {
  const current = useInput({ source });
  const { setValue } = useFormContext();
  const dependedField = useMemo(() => {
    if (dependedSource && source) {
      const items = source.split('.');
      if (items === 1) {
        return dependedSource;
      }
      items[items.length - 1] = dependedSource;
      return items.join('.');
    }
    return null;
  }, [source, dependedSource]);

  const { field } = useInput({ source: dependedField ?? '' });
  const { data, isLoading } = useAddress(
    type,
    dependedSource ? field.value : null
  );

  useEffect(() => {
    if (current.field.value) {
      const $select = document.getElementById(source);
      if ($select) {
        setValue(`${source}Name`, $select.textContent);
      }
    }
  }, [current.field, source, setValue]);

  useEffect(() => {
    const $select = document.getElementById(source);
    if ($select) {
      setValue(`${source}Name`, $select.textContent);
    }
  }, [setValue, source]);

  return (
    <SelectInput
      validate={validate}
      defaultValue={defaultValue}
      label={isLoading ? 'Đang tải' : label}
      optionText="name"
      optionValue={optionValue}
      disabled={isLoading || disabled}
      fullWidth={fullWidth}
      className={className}
      source={source}
      choices={data ?? []}
      helperText={helperText}
    />
  );
};

export default SelectAddress;
