import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { AffiliatEdit } from './CreateEdit';
import { AffiliateList } from './List';

const AffiliateResource = {
  list: AffiliateList,
  edit: AffiliatEdit,
  name: 'affiliates',
  options: {
    label: 'CTV',
  },
  icon: PeopleAltIcon,
};

export default AffiliateResource;
