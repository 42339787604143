import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  SelectInput,
} from 'react-admin';
import React from 'react';
import shared from 'shared';
import Grid from '@mui/material/Grid';
import MyEditor from 'components/FormControls/TextEditor';
import ImagePicker from 'components/FormControls/ImagePicker';

const handleTransform = (values) => {
  // Get description from HTML
  const $root = document.getElementById('root');
  const para = document.createElement('div');
  para.id = 'tinymce_content';
  para.className = 'hidden';
  para.innerHTML = values.content;
  $root.appendChild(para);
  const $tinymce = document.getElementById('tinymce_content');
  if ($tinymce) {
    let description = '';
    const $fristP = $tinymce.querySelector('p');
    if ($fristP && $fristP.textContent) {
      description = $fristP.textContent;
    }
    setTimeout(() => {
      $tinymce.remove();
    }, 1000);

    return {
      ...values,
      description,
    };
  }

  return {
    ...values,
    description: '',
  };
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const Form = () => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <TextInput
            label="Tiều đề"
            source="title"
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            choices={shared.Constants.NEWS_TYPE}
            label="Thể loại"
            source="type"
            validate={[required()]}
            fullWidth
          />
        </Grid>
      </Grid>
      <ImagePicker
        validate={[required()]}
        source="thumbnail"
        label="Hình ảnh hiển thị - Thumbnail"
      />
      <MyEditor label="Mô tả" source="content" />
    </SimpleForm>
  );
};

export const NewsEdit = () => {
  return (
    <Edit
      transform={handleTransform}
      mutationMode="pessimistic"
      redirect="list"
      title="Chỉnh sửa"
    >
      <Form />
    </Edit>
  );
};

export const NewsCreate = () => {
  return (
    <Create transform={handleTransform} redirect="list">
      <Form />
    </Create>
  );
};
