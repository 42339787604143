import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  DateField,
  usePermissions,
} from 'react-admin';
import shared from 'shared';
import StatusField from 'components/Fields/StatusField';
import Filter from './Filter';
import Expand from './Expand';
import ActionField from './ActionField';

export const BookingList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      actions={<Filter permissions={permissions} />}
      exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={shared.Constants.PAGE_SIZE}
      pagination={
        <Pagination rowsPerPageOptions={[shared.Constants.PAGE_SIZE]} />
      }
    >
      <Datagrid
        expandSingle
        expand={<Expand permissions={permissions} />}
        bulkActionButtons={false}
      >
        <DateField
          showTime
          locales="vi-VN"
          label="Ngày tạo"
          source="createdAt"
        />
        <StatusField
          data={shared.Constants.BOOKING_STATUS}
          label="Trạng thái"
        />
        <TextField sortable={false} label="Khách hàng" source="name" />
        <TextField sortable={false} label="SĐT" source="phone" />
        <ActionField permissions={permissions} />
      </Datagrid>
    </List>
  );
};
