import { useGetList } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DataGrid } from '@mui/x-data-grid';
import shared from 'shared';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Search from 'components/FormControls/Search';
import {
  CommuneFilters,
  StatusFilters,
  TermFilters,
  PriceFilters,
  DistrictsFilters,
  SpecificationsFilters,
} from 'components/SharedFilters';
import { DEFAULT_FILTER_VALUES } from './CreateEdit';

const PAGE_SIZE = 10;

const COLUMNS = [
  {
    field: 'code',
    headerName: 'Mã căn hộ',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  },
  {
    field: 'price',
    headerName: 'Giá thuê',
    width: 150,
    valueGetter: (params) => {
      if (params.row.term === 2) {
        return `${shared.currencyFormat(params.row.price)} / tháng`;
      }
      return `${shared.currencyFormat(params.row.price)} / ngày`;
    },
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  },
  {
    field: 'status',
    headerName: 'Trang thái',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  },
  {
    field: 'address',
    headerName: 'Địa chỉ',
    width: 220,
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  },
  {
    field: 'communeName',
    headerName: 'Phường',
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  },
  {
    field: 'districtName',
    headerName: 'Quận',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
  },
];

const Filters = ({ onSearch }) => {
  return (
    <>
      <p className="mb-1 text-lg font-semibold">Chọn căn hộ chỉ định</p>
      <div className="w-full grid grid-cols-4 gap-4 lg:grid-cols-2">
        <Search source="productCode" label="Mã căn" />
        <StatusFilters disabled={true} source="productStatus" />
        <TermFilters />
        <SpecificationsFilters />
        <DistrictsFilters />
        <CommuneFilters />
        <Search source="address" label="Số nhà, tên đường" />
        <PriceFilters />
      </div>
      <div className="flex w-full mt-3 mb-5">
        <Button onClick={onSearch} variant="contained">
          Tìm kiếm
        </Button>
      </div>
      <div></div>
    </>
  );
};

const DataTable = ({
  total,
  isLoading,
  data,
  pagination,
  selectedRows,
  onPaginationModelChange,
  onRowSelectionModelChange,
}) => {
  return (
    <div>
      <DataGrid
        getRowId={(row) => row.code}
        localeText={{
          footerRowSelected: (count) => <span>{count} căn được chọn</span>,
        }}
        keepNonExistentRowsSelected
        rowCount={total ?? 0}
        loading={isLoading}
        rows={data ?? []}
        columns={COLUMNS}
        paginationModel={pagination}
        paginationMode="server"
        pageSizeOptions={[PAGE_SIZE]}
        checkboxSelection
        disableRowSelectionOnClick
        onPaginationModelChange={onPaginationModelChange}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={selectedRows}
      />
    </div>
  );
};

const SelectedItems = ({ data, onDelete }) => {
  const handleClick = (item) => {
    onDelete(item);
  };

  const renderItem = (item, index) => {
    return (
      <Chip
        sx={{ marginRight: 1, marginBottom: 1 }}
        color="primary"
        key={index}
        label={item}
        onDelete={() => handleClick(item)}
      />
    );
  };

  return (
    <div className="mb-5">
      <p className="mb-2 text-lg font-semibold">Danh sách căn hộ đã chọn</p>
      {data.map(renderItem)}
    </div>
  );
};

const SelectProducts = () => {
  const { getValues, setValue } = useFormContext();
  const [filter, setFilter] = useState({
    ...DEFAULT_FILTER_VALUES,
    code: '',
    status: DEFAULT_FILTER_VALUES.productStatus,
  });
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [selectedRows, setRowSelection] = useState([]);
  const { data, isLoading, total } = useGetList(
    'products',
    {
      pagination: {
        page: pagination.page + 1,
        pageSize: PAGE_SIZE,
      },
      filter: filter,
    },
    {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const values = getValues();
    setRowSelection(values?.products ?? []);
  }, [getValues]);

  const handleClick = () => {
    const values = getValues();
    const {
      productCode,
      productStatus,
      price,
      address,
      term,
      specifications,
      district,
      commune,
    } = values;
    const queryParams = {
      code: productCode,
      status: productStatus,
      price,
      address,
      term,
      specifications,
      district,
      commune,
    };
    setFilter(queryParams);
  };

  const handleChangeRowSelection = (values) => {
    setRowSelection(values);
    setValue('products', values, { shouldDirty: true });
  };

  const handleItemDelete = (item) => {
    const rows = selectedRows.filter((v) => v !== item);
    handleChangeRowSelection(rows);
  };

  return (
    <>
      <SelectedItems onDelete={handleItemDelete} data={selectedRows} />
      <Filters onSearch={handleClick} />
      <DataTable
        onPaginationModelChange={setPagination}
        onRowSelectionModelChange={handleChangeRowSelection}
        data={data}
        pagination={pagination}
        isLoading={isLoading}
        total={total}
        selectedRows={selectedRows}
      />
    </>
  );
};

export default SelectProducts;
