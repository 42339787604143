import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  FunctionField,
  NumberField,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import shared from 'shared';
import StatusField from 'components/Fields/StatusField';
import SingleImageField from 'components/Fields/SingleImageField';
import Filter from './Filter';
import Expand from './Expand';

const CodeField = () => {
  return (
    <FunctionField
      render={(record) => {
        const link = `${process.env.REACT_APP_WEB_URL}/thue-phong/${record.slug}`;
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a target="_blank" href={link}>
            {record.code}
          </a>
        );
      }}
    />
  );
};

export const ProductList = () => {
  return (
    <List
      actions={<Filter />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      perPage={shared.Constants.PAGE_SIZE}
      pagination={
        <Pagination rowsPerPageOptions={[shared.Constants.PAGE_SIZE]} />
      }
      exporter={false}
    >
      <Datagrid
        expandSingle
        expand={<Expand />}
        optimized
        bulkActionButtons={false}
      >
        <CodeField label="Mã căn" source="code" />
        <SingleImageField sortable={true} source="images" label="Hình ảnh" />
        <StatusField
          data={shared.Constants.PRODUCT_STATUS}
          label="Trạng thái"
        />
        <TextField
          sortable={false}
          label="Số nhà - Tên đường"
          source="address"
        />
        <TextField sortable={false} label="Phường" source="communeName" />
        <TextField sortable={false} label="Quận" source="districtName" />
        <NumberField
          className="!font-semibold !text-red-600"
          sortable={true}
          label="Giá thuê"
          source="price"
          locales="vi-VN"
          options={{ style: 'currency', currency: 'VND' }}
        />
        <EditButton />
        <DeleteWithConfirmButton
          confirmTitle="Bạn có muốn xóa dữ liệu này?"
          confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
        />
      </Datagrid>
    </List>
  );
};
