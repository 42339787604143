import React from 'react';

const AuthLayout = ({ children, title }) => {
  return (
    <div className="w-screen h-screen pt-40 bg-gradient-dark">
      <div className="max-w-[400px] mx-auto">
        <div className="bg-white block shadow">
          <div className="flex items-center justify-center w-full mb-5">
            <div className="relative w-[250px] h-[100px] overflow-hidden">
              <img
                className="w-full h-[200px] absolute top-[-30px] left-0"
                src="/images/logo_white.png"
                alt=""
              />
            </div>
          </div>
          <h3 className="text-center mb-0">{title}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
