import useSWRMutation from 'swr/mutation';
import http from 'providers/HttpProvider';

const fetcher = (_, { arg }) => {
  return http.put(`/booking/${arg.id}`, arg);
};

export const useUpdateBooking = ({ onSuccess }) => {
  const { trigger, isMutating, error } = useSWRMutation(
    'update-booking',
    fetcher,
    { onSuccess }
  );
  return {
    trigger,
    isMutating,
    error,
  };
};
