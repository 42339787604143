import React, { useCallback, useMemo, useRef } from 'react';
import {
  DeleteWithConfirmButton,
  FunctionField,
  useNotify,
  useRefresh,
  SimpleForm,
  TextInput,
  required,
  SaveButton,
  Toolbar,
  SelectInput,
} from 'react-admin';
import shared from 'shared';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from 'components/Dialog';
import { LoadingProgress } from 'components/LoadingProgress';
import { useUpdateBooking } from 'hooks/useBooking';
import SelectResourceInput from 'components/FormControls/SelectResourceInput';
import DatePicker from 'components/FormControls/DatePicker';

const MyToolbar = ({ isLoading, close }) => {
  return (
    <>
      <Toolbar className="flex-col py-3">
        <SaveButton
          disabled={isLoading}
          icon={isLoading ? <LoadingProgress /> : null}
          type="submit"
          fullWidth
          label="LƯU"
        />
        <Button
          disabled={isLoading}
          onClick={close}
          startIcon={<CloseIcon />}
          style={{ marginTop: 10 }}
          color="error"
          fullWidth
          variant="outlined"
        >
          ĐÓNG
        </Button>
      </Toolbar>
    </>
  );
};

const ActionField = ({ permissions }) => {
  const dialogRef = useRef();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClose = useCallback(() => {
    dialogRef.current?.close();
  }, []);

  const handleOpen = useCallback(() => {
    dialogRef.current?.open();
  }, []);

  const onSuccess = useCallback(() => {
    refresh();
    handleClose();
  }, [refresh, handleClose]);

  const { trigger, isMutating } = useUpdateBooking({ onSuccess });

  const handleSubmit = async (values) => {
    try {
      await trigger({ ...values });
      notify('Thành công', { type: 'success' });
    } catch (error) {
      notify(error.message || 'Đã xảy ra lỗi, vui lòng thử lại sau', {
        type: 'error',
      });
    }
  };

  const statusOptions = useMemo(() => {
    return Object.values(shared.Constants.BOOKING_STATUS).map((item) => {
      return {
        id: item.VALUE,
        name: item.TEXT,
      };
    });
  }, []);

  if (permissions !== shared.Constants.ROLE_TYPES.ADMIN) {
    return null;
  }

  return (
    <FunctionField
      render={(record) => {
        return (
          <>
            <Dialog
              backdropClose={false}
              title="CHỈNH SỬA THÔNG TIN TƯ VẤN"
              ref={dialogRef}
            >
              <SimpleForm
                defaultValues={{
                  status: record.status,
                  comment: record.comment ?? '',
                  id: record.id,
                }}
                toolbar={
                  <MyToolbar isLoading={isMutating} close={handleClose} />
                }
                className="min-w-[400px]"
                onSubmit={handleSubmit}
              >
                <SelectInput
                  label="Trạng thái"
                  source="status"
                  validate={[required()]}
                  fullWidth
                  choices={statusOptions}
                />

                <SelectResourceInput
                  validate={[required()]}
                  params={{
                    filter: { role: shared.Constants.ROLE_TYPES.EMPLOYEE },
                  }}
                  source="assigned"
                  optionValue="id"
                  optionText="name"
                  resource="users"
                  label="Chọn nhân viên"
                />
                <DatePicker
                  minDate={dayjs()}
                  label="Chọn ngày hẹn"
                  name="appointmentDate"
                />

                <TextInput
                  fullWidth
                  label="Thông tin liên hệ"
                  source="contact"
                />

                <TextInput fullWidth label="Địa điểm hẹn" source="location" />

                <TextInput
                  multiline
                  rows={2}
                  fullWidth
                  label="Bình luận"
                  source="comment"
                />
              </SimpleForm>
            </Dialog>
            <Button
              style={{ marginRight: 20 }}
              onClick={handleOpen}
              startIcon={<EditIcon />}
              variant="text"
            >
              Sửa
            </Button>
            <DeleteWithConfirmButton
              confirmTitle="Bạn có muốn xóa dữ liệu này?"
              confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
            />
          </>
        );
      }}
    />
  );
};

export default ActionField;
