import React from 'react';
import { List, Datagrid, TextField, Pagination, DateField } from 'react-admin';
import shared from 'shared';
import StatusField from 'components/Fields/StatusField';
import ActionField from './ActionField';

export const ConsignmentList = () => {
  return (
    <List
      exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={shared.Constants.PAGE_SIZE}
      pagination={
        <Pagination rowsPerPageOptions={[shared.Constants.PAGE_SIZE]} />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <DateField
          showTime
          locales="vi-VN"
          label="Ngày tạo"
          source="createdAt"
        />
        <StatusField
          data={shared.Constants.CONSIGNMENT_STATUS}
          label="Trạng thái"
        />
        <TextField sortable={false} label="Khách hàng" source="name" />
        <TextField sortable={false} label="SĐT" source="phone" />
        <TextField sortable={false} label="Ghi chú KH" source="note" />
        <TextField sortable={false} label="Bình luận" source="comment" />
        <ActionField />
      </Datagrid>
    </List>
  );
};
