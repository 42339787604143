import React, { useCallback } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  DateField,
  DeleteWithConfirmButton,
  FunctionField,
  useRefresh,
  useNotify,
} from 'react-admin';
import shared from 'shared';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StatusField from 'components/Fields/StatusField';
import { useUpdateAffiliate } from 'hooks/useAffiliate';

const ActionField = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = useCallback(() => {
    refresh();
  }, [refresh]);

  const { trigger, isMutating } = useUpdateAffiliate({ onSuccess });
  const handleEdit = (record) => {
    navigate(`/affiliates/${record.id}`);
  };

  const handleUpdateStatus = async (record, status) => {
    try {
      await trigger({ ...record, status });
      notify('Thành công', { type: 'success' });
    } catch (error) {
      notify(error.message || 'Đã xảy ra lỗi, vui lòng thử lại sau', {
        type: 'error',
      });
    }
  };

  return (
    <FunctionField
      render={(record) => {
        return (
          <>
            {record.status ===
              shared.Constants.AFFILIATE_STATUS.WATING_CONFIRM.VALUE && (
              <Button
                disabled={isMutating}
                color="success"
                onClick={() =>
                  handleUpdateStatus(
                    record,
                    shared.Constants.AFFILIATE_STATUS.APPROVED.VALUE
                  )
                }
                startIcon={<CheckIcon />}
                variant="text"
              >
                Chấp thuận
              </Button>
            )}
            {record.status ===
              shared.Constants.AFFILIATE_STATUS.WATING_CONFIRM.VALUE && (
              <Button
                disabled={isMutating}
                color="error"
                onClick={() =>
                  handleUpdateStatus(
                    record,
                    shared.Constants.AFFILIATE_STATUS.REJECTED.VALUE
                  )
                }
                startIcon={<ClearIcon />}
                variant="text"
              >
                Từ chối
              </Button>
            )}
            {record.status !==
              shared.Constants.AFFILIATE_STATUS.WATING_CONFIRM.VALUE && (
              <Button
                style={{ marginRight: 20 }}
                onClick={() => handleEdit(record)}
                startIcon={<EditIcon />}
                variant="text"
              >
                Sửa
              </Button>
            )}
            {record.status !==
              shared.Constants.AFFILIATE_STATUS.WATING_CONFIRM.VALUE && (
              <DeleteWithConfirmButton
                confirmTitle="Bạn có muốn xóa dữ liệu này?"
                confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
              />
            )}
          </>
        );
      }}
    />
  );
};

const LinkField = () => {
  return (
    <FunctionField
      render={(record) => {
        if (record.link) {
          return (
            // eslint-disable-next-line
            <a target="_blank" href={record.link} className="w-[150px] block">
              Xem CV
            </a>
          );
        }
        return null;
      }}
    />
  );
};

const CodeField = () => {
  return (
    <FunctionField
      render={(record) => {
        if (
          record.status === shared.Constants.AFFILIATE_STATUS.APPROVED.VALUE
        ) {
          return <span>{record.code}</span>;
        }
        return null;
      }}
    />
  );
};

export const AffiliateList = () => {
  return (
    <List
      sort={{ field: 'updatedAt', order: 'DESC' }}
      perPage={shared.Constants.PAGE_SIZE}
      pagination={
        <Pagination rowsPerPageOptions={[shared.Constants.PAGE_SIZE]} />
      }
      exporter={false}
    >
      <Datagrid optimized bulkActionButtons={false}>
        <DateField locales="vi-VN" label="Ngày tạo" source="createdAt" />
        <StatusField
          data={shared.Constants.AFFILIATE_STATUS}
          label="Trạng thái"
        />
        <CodeField sortable={false} label="Mã CTV" />
        <TextField sortable={false} label="Tên" source="name" />
        <TextField sortable={false} label="CCCD" source="idenfier" />
        <TextField sortable={false} label="Vị trí" source="position" />
        <TextField sortable={false} label="SĐT" source="phone" />
        <LinkField label="CV" />
        <ActionField />
      </Datagrid>
    </List>
  );
};
