import { useMemo } from 'react';
import shared from 'shared';
import http from 'providers/HttpProvider';
import {
  Edit,
  Create,
  useEditContext,
  Toolbar,
  SaveButton,
  TextInput,
  required,
  SelectInput,
  NumberInput,
  SelectArrayInput,
  TabbedForm,
} from 'react-admin';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MyEditor from 'components/FormControls/TextEditor';
import CurrencyInput from 'components/FormControls/CurrencyInput';
import ImagePicker from 'components/FormControls/ImagePicker';
import SelectAddress from 'components/FormControls/SelectAddress';

const DEFAULT_VALUES = {
  term: '',
  status: 'Available',
  province: '79',
  provinceName: 'Tp Hồ Chí Minh',
};

const FormToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const handleTransform = async (values) => {
  const {
    primaryPrice,
    street,
    houseNumber,
    communeName,
    districtName,
    provinceName,
    specificationItems,
    ...rest
  } = values;
  let address = street;
  if (houseNumber && houseNumber !== 'MT') {
    address = `${houseNumber} ${address}`;
  }
  const { data } = await http
    .get('/common/geocode', {
      params: {
        address: address,
        commune: communeName,
        district: districtName,
        province: provinceName,
      },
    })
    .then((res) => res.data);

  return {
    ...rest,
    street,
    houseNumber,
    communeName,
    districtName,
    provinceName,
    lat: data.lat,
    lng: data.lng,
    price: primaryPrice,
    specifications: specificationItems
      ? specificationItems
          .filter((item) =>
            shared.Constants.SPECIFICATIONS.some((v) => v.id === item)
          )
          .map((item) => ({
            id: item,
            name: shared.Constants.SPECIFICATIONS.find((v) => v.id === item)
              ?.name,
          }))
      : [],
  };
};

const PrimaryForm = () => {
  const statusChoices = useMemo(() => {
    return Object.values(shared.Constants.PRODUCT_STATUS).map((option) => {
      return {
        name: option.TEXT,
        id: option.VALUE,
      };
    });
  }, []);
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <TextInput
          label="Mã căn"
          source="code"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput label="Mã phòng" source="room" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <CurrencyInput
          label="Giá cho thuê"
          source="primaryPrice"
          helperText="Số tiền khách hàng cần thanh toán"
          validate={[required()]}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <SelectAddress
          label="Chọn quận/huyện"
          optionValue="idDistrict"
          type="district"
          source="district"
          dependedSource="province"
          validate={[required()]}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectAddress
          label="Chọn xã/phường"
          optionValue="idCommune"
          source="commune"
          type="commune"
          dependedSource="district"
          validate={[required()]}
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Tên đường"
          source="street"
          validate={[required()]}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Số nhà" source="houseNumber" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <SelectInput
          label="Thời gian thuê"
          source="term"
          validate={[required()]}
          fullWidth
          choices={shared.Constants.TERMS}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectInput
          validate={[required()]}
          label="Trạng thái"
          source="status"
          fullWidth
          choices={statusChoices}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectArrayInput
          optionText="name"
          optionValue="id"
          label="Đặc điểm"
          source="specificationItems"
          fullWidth
          choices={shared.Constants.SPECIFICATIONS}
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Nội thất" source="interior" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <NumberInput
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                m<sup>2</sup>
              </InputAdornment>
            ),
          }}
          fullWidth
          label="Diện tích"
          source="acreage"
        />
      </Grid>

      <Grid item xs={12}>
        <MyEditor label="Mô tả" source="description" />
      </Grid>

      <Grid item xs={12}>
        <ImagePicker
          validate={[required()]}
          label="Hình ảnh"
          multiple
          source="images"
        />
      </Grid>
    </Grid>
  );
};

const SecondaryForm = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <TextInput label="Tên chủ nhà" source="owner.name" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput label="SĐT chủ nhà" source="owner.contact" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Tên người quản lí"
          source="owner.managerName"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="SĐT người quản lí"
          source="owner.managerPhone"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput label="Địa chỉ thật" source="owner.address" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput label="Hoa hồng" source="owner.bonus" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextInput label="Link" source="owner.link" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Ghi chú" source="owner.note" fullWidth />
      </Grid>
    </Grid>
  );
};

const Form = ({ defaultValues }) => {
  return (
    <TabbedForm toolbar={<FormToolbar />} defaultValues={defaultValues}>
      <TabbedForm.Tab label="Thông tin căn hộ">
        <PrimaryForm />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Thông tin chủ nhà">
        <SecondaryForm />
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

const EditForm = () => {
  const { record } = useEditContext();
  const defaultValues = useMemo(() => {
    return {
      ...DEFAULT_VALUES,
      ...record,
      specificationItems: record.specifications.map((option) => option.id),
      primaryPrice: record.price,
    };
  }, [record]);
  return <Form defaultValues={defaultValues} type="edit" />;
};

const CreateForm = () => {
  return <Form defaultValues={DEFAULT_VALUES} type="create" />;
};

export const ProductEdit = () => {
  return (
    <Edit
      transform={handleTransform}
      mutationMode="pessimistic"
      redirect="list"
      title="Chỉnh sửa"
    >
      <EditForm />
    </Edit>
  );
};

export const ProductCreate = () => {
  return (
    <Create transform={handleTransform} redirect="list">
      <CreateForm />
    </Create>
  );
};
