import 'dayjs/locale/vi';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MUIDatePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const FORMAT_TYPE = 'YYYY-MM-DD HH:mm';

const DatePicker = ({
  defaultValue,
  maxDate,
  minDate,
  name,
  label = 'Chọn ngày',
}) => {
  const isSetDefaultValue = useRef(false);
  const { setValue: setFormValue } = useFormContext();
  const initialValue = useMemo(() => {
    return defaultValue ? dayjs(defaultValue, FORMAT_TYPE) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [value, setValue] = useState(initialValue);

  const handleChange = (value) => {
    setValue(value);
    setFormValue(name, value.format(FORMAT_TYPE), {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    // Handle the case reset button clicked
    if (isSetDefaultValue) {
      if (!defaultValue) {
        setValue(initialValue);
      }
    } else {
      // Handle the case parse default values from URL
      if (defaultValue) {
        setValue(defaultValue);
        isSetDefaultValue.current = true;
      }
    }
  }, [defaultValue, initialValue]);

  return (
    <LocalizationProvider adapterLocale="vi" dateAdapter={AdapterDayjs}>
      <MUIDatePicker
        slotProps={{
          textField: {
            helperText: <span className="text-white select-none">2121</span>,
          },
        }}
        className="w-full"
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={(value) => handleChange(value)}
        label={label}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
