import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { BookingList } from './List';

const BookingResource = {
  list: BookingList,
  name: 'booking',
  options: {
    label: 'Tư vấn',
  },
  icon: SupportAgentIcon,
};

export default BookingResource;
