import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  PasswordInput,
  email,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyIcon from '@mui/icons-material/Key';
import Button from '@mui/material/Button';
import { useLogin } from 'hooks/useAuth';
import AuthLayout from 'components/AuthLayout';
import { LoadingProgress } from 'components/LoadingProgress';

const MyToolbar = ({ isLoading }) => {
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <>
      <Toolbar className="flex-col py-3">
        <SaveButton
          disabled={isLoading}
          alwaysEnable
          type="submit"
          fullWidth
          label="ĐĂNG NHẬP"
          icon={isLoading ? <LoadingProgress /> : <ExitToAppIcon />}
        />
        <Button
          onClick={handleForgotPassword}
          fullWidth
          color="error"
          style={{ marginTop: 10 }}
          startIcon={<KeyIcon />}
          type="button"
        >
          QUÊN MẬT KHẨU
        </Button>
      </Toolbar>
    </>
  );
};

const Login = () => {
  const { trigger, isMutating } = useLogin();

  const handleSubmit = async (values) => {
    try {
      await trigger(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout title="Đăng nhập để tiếp tục">
      <SimpleForm
        className="bg-white block shadow"
        toolbar={<MyToolbar isLoading={isMutating} />}
        onSubmit={handleSubmit}
      >
        <TextInput
          fullWidth
          validate={[required(), email()]}
          label="Email"
          source="email"
        />
        <PasswordInput
          fullWidth
          validate={[required()]}
          label="Mật khẩu"
          source="password"
        />
      </SimpleForm>
    </AuthLayout>
  );
};

export default Login;
