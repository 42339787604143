import dayjs from 'dayjs';
import shared from 'shared';

const HTMLViewer = ({ className = '', html }) => {
  if (!html) {
    return null;
  }
  return (
    <div
      className={className}
      id="html-viewer"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

const Expand = ({ record }) => {
  const {
    specifications,
    interior,
    description,
    updatedAt,
    acreage,
    owner,
    availableDate,
    term,
  } = record;
  return (
    <div className="bg-white p-3">
      <div className="grid grid-cols-5 gap-4">
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Ngày tạo</p>
          <p>{dayjs(updatedAt).format('DD/MM/YYYY')}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Thời gian</p>
          <p>{shared.Constants.TERMS.find((v) => v.id === term)?.name}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Ngày trống</p>
          {availableDate && <p>{availableDate}</p>}
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Diện tích</p>
          {acreage && (
            <p>
              {acreage} m<sup>2</sup>
            </p>
          )}
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Đặc điểm</p>
          <p>{specifications.map((item) => item.name).join(', ')}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Nội thất</p>
          <p>{interior}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Mô tả</p>
          <HTMLViewer html={description} />
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Tên chủ nhà</p>
          <p>{owner?.name}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">SĐT chủ nhà</p>
          <p>{owner?.contact}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Tên người quản lý</p>
          <p>{owner?.managerName}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">SĐT quản lý</p>
          <p>{owner?.managerPhone}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Địa chỉ thật</p>
          <p>{owner?.address}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Hoa hồng</p>
          <p>{owner?.bonus}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Link</p>
          {owner?.link && (
            // eslint-disable-next-line
            <a target="_blank" href={owner.link}>
              {owner.link}
            </a>
          )}
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Ghi chú</p>
          <p>{owner?.note}</p>
        </div>
      </div>
    </div>
  );
};

export default Expand;
