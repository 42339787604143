import React from 'react';
import shared from 'shared';
import { Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import MainLayout from 'components/MainLayout';
import ChangePassword from 'pages/ChangePassword';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import ProductResource from 'resource/product';
import AdminResource from 'resource/admin';
import NewsResource from 'resource/news';
import BookingResource from 'resource/booking';
import SettingResource from 'resource/setting';
import AffiliateResource from 'resource/affiliate';
import ConsignmentResource from 'resource/consignment';

const App = () => {
  return (
    <MainLayout>
      {(permissions) => {
        return (
          <>
            <CustomRoutes>
              <Route path="/change-password" element={<ChangePassword />} />
            </CustomRoutes>

            <CustomRoutes noLayout>
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </CustomRoutes>

            <CustomRoutes noLayout>
              <Route path="/reset-password" element={<ResetPassword />} />
            </CustomRoutes>

            {permissions === shared.Constants.ROLE_TYPES.ADMIN && (
              <Resource {...ProductResource} />
            )}

            <Resource {...BookingResource} />

            {permissions === shared.Constants.ROLE_TYPES.ADMIN && (
              <Resource {...AffiliateResource} />
            )}

            {permissions === shared.Constants.ROLE_TYPES.ADMIN && (
              <Resource {...ConsignmentResource} />
            )}

            {permissions === shared.Constants.ROLE_TYPES.ADMIN && (
              <Resource {...AdminResource} />
            )}

            {permissions === shared.Constants.ROLE_TYPES.ADMIN && (
              <Resource {...NewsResource} />
            )}

            {permissions === shared.Constants.ROLE_TYPES.ADMIN && (
              <Resource {...SettingResource} />
            )}
          </>
        );
      }}
    </MainLayout>
  );
};

export default App;
