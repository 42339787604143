import ApartmentIcon from "@mui/icons-material/Apartment";
import { ProductCreate, ProductEdit } from "./CreateEdit";
import { ProductList } from "./List";

const ProductResource = {
  list: ProductList,
  edit: ProductEdit,
  create: ProductCreate,
  name: "products",
  options: {
    label: "Cho thuê",
  },
  icon: ApartmentIcon,
};

export default ProductResource;
