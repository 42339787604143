import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { AdminEdit, AdminCreate } from './CreateEdit';
import { AdminList } from './List';

const AdminResource = {
  list: AdminList,
  edit: AdminEdit,
  create: AdminCreate,
  name: 'users',
  options: {
    label: 'Quản trị viên',
  },
  icon: AdminPanelSettingsIcon,
};

export default AdminResource;
