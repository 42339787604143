import React from 'react';
import { FunctionField } from 'react-admin';

const StatusField = ({ data }) => {
  return (
    <FunctionField
      render={(record) => {
        const status = Object.values(data).find(
          (v) => v.VALUE === record.status
        );
        if (!status) {
          return null;
        }
        return (
          <div className="flex items-center justify-start w-[130px]">
            <div className="mr-1">
              <div
                className="rounded py-1 px-2 flex items-center justify-center"
                style={{ backgroundColor: status.COLOR }}
              >
                <span className="font-semibold text-white text-sm">
                  {status.TEXT}
                </span>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default StatusField;
