import { useCallback } from 'react';
import { useNotify } from 'react-admin';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import http from 'providers/HttpProvider';
import FileProvider from 'providers/FileProvider';

const fetchSubmit = async (_, { arg }) => {
  if (arg.values.banner && arg.values.banner.rawFile) {
    const response = await FileProvider.uploadImage(arg.values.banner.rawFile, {
      public_id: 'banner',
    });
    arg.values.banner = response;
  }
  return http.post('/common/setting', arg);
};

const fetcheGet = async () => {
  const { data } = await http.get('/common/setting');
  return data;
};

export const useSubmitSetting = (reloadWhenSuccess = false) => {
  const notify = useNotify();
  const handleSuccess = useCallback(() => {
    notify(`Đã lưu thành công`, { type: 'success' });
    if (reloadWhenSuccess) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  }, [notify, reloadWhenSuccess]);

  const { trigger, isMutating, error } = useSWRMutation(
    'submmit-setting',
    fetchSubmit,
    { onSuccess: handleSuccess }
  );
  return {
    trigger,
    isMutating,
    error,
  };
};

export const useGetSetting = () => {
  const { data, isLoading, ...rest } = useSWR('get-subbmit', fetcheGet, {
    revalidateOnFocus: false,
  });
  return {
    ...data,
    isLoading,
    ...rest,
  };
};
