import http from './HttpProvider';

let UPLOADED_IMAGES = [];

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const deleteImage = async (publicId) => {
  return await http.delete(`/common/image/delete/${publicId}`);
};

const uploadImage = async (file, options = {}) => {
  try {
    const base64 = await toBase64(file);
    const body = { file: base64, options };
    const { data } = await http.post('/common/image/upload', body);

    UPLOADED_IMAGES = [...UPLOADED_IMAGES, data.id];

    return data;
  } catch (error) {
    console.log(error);
    return {
      src: '',
    };
  }
};

const clearStorage = () => {
  UPLOADED_IMAGES = [];
};

const removeUploadedImages = async () => {
  try {
    if (UPLOADED_IMAGES && UPLOADED_IMAGES.length > 0) {
      const promises = UPLOADED_IMAGES.map((id) => deleteImage(id));
      await Promise.allSettled(promises);
    }
  } catch (error) {
    console.error(error);
  } finally {
    clearStorage();
  }
};

const FileProvider = {
  uploadImage,
  deleteImage,
  removeUploadedImages,
  clearStorage,
};

export default FileProvider;
