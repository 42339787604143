import React from 'react';
import {
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  PasswordInput,
  minLength,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import AuthLayout from 'components/AuthLayout';
import { useResetPassword } from 'hooks/useAuth';
import { LoadingProgress } from 'components/LoadingProgress';

const MyToolbar = ({ isLoading }) => {
  return (
    <>
      <Toolbar className="flex-col py-3">
        <SaveButton
          disabled={isLoading}
          icon={isLoading ? <LoadingProgress /> : null}
          type="submit"
          fullWidth
          label="CẬP NHẬT"
        />
      </Toolbar>
    </>
  );
};

const ResetPassword = () => {
  const { trigger, isMutating } = useResetPassword();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const handleSubmit = async (values) => {
    try {
      await trigger({
        token,
        ...values,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout title="Đặt lại mật khẩu">
      <SimpleForm
        className="bg-white block shadow"
        toolbar={<MyToolbar isLoading={isMutating} />}
        onSubmit={handleSubmit}
      >
        <PasswordInput
          initiallyVisible={true}
          validate={[
            required(),
            minLength(8, 'Mật khẩu ít nhất phải chứa 8 kí tự'),
          ]}
          label="Mật khẩu mới"
          fullWidth
          source="password"
        />
      </SimpleForm>
    </AuthLayout>
  );
};

export default ResetPassword;
