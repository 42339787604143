import { useRecordContext } from 'react-admin';

const SingleImageField = ({ source, ...props }) => {
  const record = useRecordContext(props);
  const image =
    record && record.images.length > 0 ? record.images[0].src : null;

  return image ? (
    <img
      alt=""
      className="w-[40px] h-[40px] object-cover rounded"
      src={image}
    />
  ) : null;
};

export default SingleImageField;
