import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ConsignmentList } from './List';

const ConsignmentResource = {
  list: ConsignmentList,
  name: 'consignments',
  options: {
    label: 'Ký gửi',
  },
  icon: AccountTreeIcon,
};

export default ConsignmentResource;
