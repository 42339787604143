import FileProvider from 'providers/FileProvider';
import React, { useState } from 'react';
import { Confirm, ImageInput, ImageField, useInput } from 'react-admin';

const ImagePicker = ({
  multiple = false,
  source = 'image',
  validate = null,
  label,
}) => {
  const [removeImage, setRemoveImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { field } = useInput({ source });

  const handleValidateFileRemoval = async (file) => {
    // Only show the confirmation modal when deleting a remoted image
    if (file.id) {
      const promise = new Promise((resolve, reject) => {
        setRemoveImage({
          fileName: `Image ID: ${file.id}`,
          delete: async (result) => {
            await FileProvider.deleteImage(file.id);
            // Trigger onChange to form
            field.onChange('');
            return resolve(result);
          },
          cancel: reject,
        });
      });
      setShowModal(true);
      return promise.then();
    }
    return Promise.resolve().then();
  };

  return (
    <>
      <ImageInput
        validate={validate}
        multiple={multiple}
        maxSize={1000000}
        source={source}
        label={
          label
            ? `${label} (kích thước ảnh tối đa là 1MB. Nếu hình lớn hơn hãy truy cập vào https://www.iloveimg.com/compress-image)`
            : false
        }
        accept=".png, .jpg, .jpeg"
        validateFileRemoval={handleValidateFileRemoval}
      >
        <ImageField source="src" />
      </ImageInput>
      <Confirm
        isOpen={showModal}
        title="Bạn muốn xóa hình ảnh này?"
        content="Hình ảnh sẽ bị xóa và không thể khôi phục, hãy xác nhận"
        onConfirm={() => {
          setShowModal(false);
          removeImage && removeImage.delete();
        }}
        onClose={() => {
          setShowModal(false);
          removeImage && removeImage.cancel();
        }}
      />
    </>
  );
};

export default ImagePicker;
