import React, { useMemo } from 'react';
import { CreateButton, TopToolbar, useListContext } from 'react-admin';
import { Box, Button } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import Search from 'components/FormControls/Search';
import {
  DEFAULT_FILTERS,
  CommuneFilters,
  StatusFilters,
  TermFilters,
  PriceFilters,
  DistrictsFilters,
  SpecificationsFilters,
} from 'components/SharedFilters';

const FilterInputs = () => {
  const { filterValues, setFilters } = useListContext();
  const defaultValues = useMemo(() => {
    return {
      ...DEFAULT_FILTERS,
      ...filterValues,
    };
  }, [filterValues]);
  const form = useForm({ defaultValues });

  const onSubmit = (values) => {
    const {
      street,
      code,
      commune,
      district,
      specifications,
      status,
      term,
      price,
    } = values;
    setFilters({
      street,
      code,
      commune,
      district,
      price,
      specifications,
      status,
      term,
    });
  };

  const handleReset = () => {
    form.reset(DEFAULT_FILTERS);
    setFilters(DEFAULT_FILTERS);
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="start" mb={1}>
            <div className="w-full grid grid-cols-4 gap-4 lg:grid-cols-2">
              <Search source="code" label="Mã căn" />
              <StatusFilters />
              <TermFilters />
              <SpecificationsFilters />
              <DistrictsFilters />
              <CommuneFilters />
              <Search source="street" label="Tên đường" />
              <PriceFilters />
            </div>

            <div className="flex flex-col pt-2 ml-4 ">
              <Button
                style={{ width: 100, marginBottom: 10 }}
                variant="contained"
                color="primary"
                type="submit"
              >
                TÌM KIẾM
              </Button>
              <Button
                style={{ width: 100 }}
                onClick={handleReset}
                variant="outlined"
                color="error"
                type="button"
              >
                XÓA
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
};

const Filter = ({ hasCreate = true }) => (
  <Box width="100%">
    {hasCreate && (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    )}
    <FilterInputs />
  </Box>
);

export default Filter;
