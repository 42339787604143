import http from './HttpProvider';
import shared from 'shared';

export const removeLocalStorageItems = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
};

const fetchGetGeolocation = async (type) => {
  const response = await fetch(
    `https://api.ipgeolocation.io/${type}?apiKey=5744b8f1b7ab47d9ac19d2dd29a6387a`
  );
  const data = await response.json();
  return data;
};

const getGeolocation = async () => {
  try {
    const responses = await Promise.all([
      fetchGetGeolocation('ipgeo'),
      fetchGetGeolocation('user-agent'),
    ]);
    const [ipgeo, userAgent] = responses;
    return {
      ip: ipgeo?.ip,
      timeZone: ipgeo?.time_zone?.name,
      platform: `${userAgent?.device?.name} - ${userAgent?.name}`,
    };
  } catch (error) {
    console.log('[ERROR] getGeolocation', error);
  }
};

const getUser = () => {
  const data = localStorage.getItem('user');
  return shared.stringToJSON(data);
};

const AuthProvider = {
  login: async ({ email, password }) => {
    const geolocation = await getGeolocation();
    const { data } = await http.post(
      '/authentication/login/admin',
      {
        email,
        password,
      },
      {
        headers: {
          'Client-TZ': geolocation?.timeZone ?? 'None',
          'Client-IP': geolocation?.ip ?? 'None',
          'Client-Platform': geolocation?.platform ?? 'None',
        },
      }
    );
    const { accessToken, refreshToken, ...rest } = data.data;
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('user', JSON.stringify(rest));
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: async () => {
    removeLocalStorageItems();
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (data) => {
    const status = data?.status ?? null;
    if (status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (
      window.location.href.includes('forgot-password') ||
      window.location.href.includes('reset-password')
    ) {
      return Promise.resolve();
    }
    const token = localStorage.getItem('token');
    return token ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const user = getUser();
    if (user) {
      return Promise.resolve(user.role);
    }
    return Promise.reject();
  },

  getIdentity: async () => {
    const user = getUser();
    if (user) {
      const { name, id, role } = user;
      return Promise.resolve({
        fullName: name,
        avatar: `https://ui-avatars.com/api/?name=${name}?bold=true`,
        id,
        role,
      });
    }
    return Promise.reject();
  },
};

export default AuthProvider;
