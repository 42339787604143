import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import http from './HttpProvider';
import FileProvider from './FileProvider';

const apiUrl = 'https://my.api.com/';
const httpClient = fetchUtils.fetchJson;

const getQueryParams = (resource, params) => {
  const { page } = params.pagination;
  const { field, order } = params.sort;
  const filter = params.filter;
  let query = {
    limit: params.pagination.perPage ?? params.pagination.pageSize,
    page,
    order: field && order ? `${field},${order === 'ASC' ? 'asc' : 'desc'}` : '',
  };

  if (params.meta) {
    query = {
      ...query,
      ...params.meta,
    };
  }

  if (Object.keys(filter).length > 0) {
    const keyword = filter.q ? filter.q : '';
    if (keyword) {
      query = {
        ...query,
        keyword,
      };
    }
    if (resource === 'products') {
      const {
        street,
        code,
        commune,
        district,
        price,
        specifications,
        status,
        term,
        ids,
      } = filter;
      query = {
        ...query,
        street: street ?? '',
        code: code ?? '',
        commune: commune ?? '',
        district: district ?? '',
        status: status ?? '',
        term: term ?? '',
      };
      if (specifications) {
        query = {
          ...query,
          specifications: specifications.join(',') ?? '',
        };
      }
      if (ids && ids?.length > 0) {
        query = {
          ...query,
          ids: ids.join(',') ?? '',
        };
      }
      if (price && price.length > 0) {
        const [min, max] = price;
        query = {
          ...query,
          price: `${min},${max}`,
        };
      }
    }
    if (resource === 'users') {
      const { role } = filter;
      query = {
        ...query,
        role,
      };
    }
    if (resource === 'booking') {
      const { phone } = filter;
      query = {
        ...query,
        phone: phone ?? '',
      };
    }
  }

  return query;
};

const uploadImages = async (values, resource) => {
  switch (resource) {
    case 'products':
      const uploadedImages = values.images.filter((v) => v.id);
      const promises = values.images
        .filter((v) => v.rawFile)
        .map((v) => FileProvider.uploadImage(v.rawFile, { watermark: true }));
      const newImages = await Promise.all(promises);
      values.images = [...uploadedImages, ...newImages].filter((v) =>
        v.src?.startsWith('https://res.cloudinary.com')
      );
      break;
    case 'news':
      if (values.thumbnail && values.thumbnail.rawFile) {
        values.thumbnail = await FileProvider.uploadImage(
          values.thumbnail.rawFile
        );
      }
      break;
    default:
      break;
  }
  return values;
};

const AppProvider = {
  update: async (resource, params) => {
    try {
      const body = await uploadImages(params.data, resource);
      const { data } = await http.put(`/${resource}/${params.id}`, body);
      FileProvider.clearStorage();
      return data;
    } catch (error) {
      // Need to delete images that uploaded before
      FileProvider.removeUploadedImages();
      throw error;
    }
  },

  create: async (resource, params) => {
    try {
      const body = await uploadImages(params.data, resource);
      const { data } = await http.post(`/${resource}`, body);
      FileProvider.clearStorage();
      return data;
    } catch (error) {
      // Need to delete images that uploaded before
      FileProvider.removeUploadedImages();
      throw error;
    }
  },

  getList: async (resource, params) => {
    if (!resource) {
      return Promise.resolve({ data: [], total: 0 });
    }
    const query = getQueryParams(resource, params);
    const { data } = await http.get(`/${resource}`, {
      params: query,
    });
    return data;
  },

  getOne: async (resource, params) => {
    const { data } = await http.get(`/${resource}/${params.id}`);
    return data;
  },

  getMany: async (resource, params) => {
    if (!resource) {
      return Promise.resolve({ data: [], total: 0 });
    }
    if (!params.filter) {
      params.filter = {
        ids: params?.ids,
      };
    }
    if (!params.pagination) {
      params.pagination = {
        page: 1,
        perPage: params?.ids?.length || 12,
      };
    }
    if (!params.sort) {
      params.sort = {
        field: '',
        order: '',
      };
    }
    const query = getQueryParams(resource, params);
    const { data } = await http.get(`/${resource}`, {
      params: query,
    });
    return data;
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: async (resource, params) => {
    const { data } = await http.delete(`/${resource}/${params.id}`);
    return data;
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const { data } = await http.delete(`/${resource}`, {
      params: query,
    });
    return data;
  },

  appConstants: async () => {
    const { data } = await http.get(`/common`);
    return data;
  },
};

export default AppProvider;
