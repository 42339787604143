import React from 'react';
import { Card } from '@mui/material';
import { PasswordInput, SimpleForm, required, minLength } from 'react-admin';
import { useChangePassword } from 'hooks/useAuth';

const ChangePassword = () => {
  const { trigger } = useChangePassword();

  const handleSubmit = async (values) => {
    try {
      await trigger(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card style={{ marginTop: 80 }}>
      <SimpleForm onSubmit={handleSubmit}>
        <PasswordInput
          initiallyVisible={true}
          validate={[
            required(),
            minLength(8, 'Mật khẩu ít nhất phải chứa 8 kí tự'),
          ]}
          label="Mật khẩu hiện tại"
          fullWidth
          source="currentPassword"
        />
        <PasswordInput
          initiallyVisible={true}
          validate={[
            required(),
            minLength(8, 'Mật khẩu ít nhất phải chứa 8 kí tự'),
          ]}
          label="Mật khẩu mới"
          fullWidth
          source="newPassword"
        />
      </SimpleForm>
    </Card>
  );
};

export default ChangePassword;
