import React, { useState, useMemo, useEffect, useRef } from 'react';
import { SelectInput, SelectArrayInput } from 'react-admin';
import shared from 'shared';
import Slider from '@mui/material/Slider';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectAddress from 'components/FormControls/SelectAddress';

export const DEFAULT_FILTERS = {
  address: '',
  price: [
    shared.Constants.LONG_TERM_PROPS.min,
    shared.Constants.LONG_TERM_PROPS.max,
  ],
  code: '',
  term: 2,
  status: '',
  specifications: '',
  district: '',
  commune: '',
};

export const CommuneFilters = () => {
  return (
    <SelectAddress
      helperText={false}
      label="Xã/phường"
      optionValue="idCommune"
      source="commune"
      type="commune"
      dependedSource="district"
    />
  );
};

export const DistrictsFilters = () => {
  return (
    <SelectAddress
      helperText={false}
      label="Quận/huyện"
      optionValue="idDistrict"
      type="district"
      source="district"
      dependedSource="province"
    />
  );
};

export const SpecificationsFilters = () => {
  return (
    <SelectArrayInput
      helperText={false}
      optionText="name"
      optionValue="id"
      fullWidth
      label="Đặc điểm"
      source="specifications"
      choices={shared.Constants.SPECIFICATIONS}
    />
  );
};

export const PriceFilters = () => {
  const { setValue: setFormValue, getValues } = useFormContext();
  const termValue = useWatch({ name: 'term' });
  const isShortTerm = termValue === 1;
  const isMounted = useRef(false);
  const values = getValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => values, []);
  const [value, setValue] = useState(initialValues.price);

  const handleChange = (_, data) => {
    setValue(data);
    setFormValue('price', data);
  };

  useEffect(() => {
    setTimeout(() => {
      isMounted.current = true;
    }, 300);
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    if (isShortTerm) {
      const value = [
        shared.Constants.SHORT_TERM_PROPS.min,
        shared.Constants.SHORT_TERM_PROPS.max,
      ];
      setValue(value);
      setFormValue('price', value);
    } else {
      const value = [
        shared.Constants.LONG_TERM_PROPS.min,
        shared.Constants.LONG_TERM_PROPS.max,
      ];
      setValue(value);
      setFormValue('price', value);
    }
  }, [isShortTerm, setValue, setFormValue]);

  const renderLabel = (values) => {
    const { min, max } = shared.parsePriceLabel(values);
    return (
      <span>
        Khoảng giá:{' '}
        <strong>
          {min} - {max}
        </strong>
      </span>
    );
  };

  return (
    <div className="w-full">
      {renderLabel(value)}
      <Slider
        min={
          isShortTerm
            ? shared.Constants.SHORT_TERM_PROPS.min
            : shared.Constants.LONG_TERM_PROPS.min
        }
        max={
          isShortTerm
            ? shared.Constants.SHORT_TERM_PROPS.max
            : shared.Constants.LONG_TERM_PROPS.max
        }
        step={
          isShortTerm
            ? shared.Constants.SHORT_TERM_PROPS.step
            : shared.Constants.LONG_TERM_PROPS.step
        }
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value / 1000000}tr`}
      />
    </div>
  );
};

export const TermFilters = () => {
  return (
    <SelectInput
      helperText={false}
      optionText="name"
      optionValue="id"
      fullWidth
      label="Thời gian"
      source="term"
      choices={shared.Constants.TERMS}
    />
  );
};

export const StatusFilters = ({ source = 'status', disabled = false }) => {
  const choices = useMemo(() => {
    return Object.values(shared.Constants.PRODUCT_STATUS).map((option) => {
      return {
        name: option.TEXT,
        id: option.VALUE,
      };
    });
  }, []);

  return (
    <SelectInput
      disabled={disabled}
      helperText={false}
      optionText="name"
      optionValue="id"
      fullWidth
      label="Trạng thái"
      source={source}
      choices={choices}
    />
  );
};
