import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  email,
} from 'react-admin';
import EastIcon from '@mui/icons-material/East';
import AuthLayout from 'components/AuthLayout';
import { useForgotPassword } from 'hooks/useAuth';
import { LoadingProgress } from 'components/LoadingProgress';

const MyToolbar = ({ isLoading }) => {
  return (
    <>
      <Toolbar className="flex-col py-3">
        <SaveButton
          disabled={isLoading}
          type="submit"
          fullWidth
          label="TIẾP TỤC"
          icon={isLoading ? <LoadingProgress /> : <EastIcon />}
        />
      </Toolbar>
    </>
  );
};

const ForgotPassword = () => {
  const { trigger, isMutating } = useForgotPassword();

  const handleSubmit = async (values) => {
    try {
      await trigger(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout title="Khôi phục mật khẩu">
      <SimpleForm
        className="bg-white block shadow"
        toolbar={<MyToolbar isLoading={isMutating} />}
        onSubmit={handleSubmit}
      >
        <TextInput
          fullWidth
          validate={[required(), email()]}
          label="Email"
          source="email"
        />
      </SimpleForm>
    </AuthLayout>
  );
};

export default ForgotPassword;
