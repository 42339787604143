import React, { useState, useImperativeHandle } from 'react';
import MUIDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Dialog = React.forwardRef(
  ({ title, children, confirm, onYes, backdropClose = true }, ref) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = (confirmed) => {
      setOpen(false);
      if (confirmed && onYes) {
        onYes();
      }
    };

    const handleBackdropClick = () => {
      if (backdropClose) {
        handleClose();
      }
    };

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    if (confirm) {
      return (
        <MUIDialog open={open} onClose={handleClose}>
          <DialogTitle>Bạn có muốn xóa dữ liệu này?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bạn sẽ không thể khôi phục dữ liệu sau khi xóa
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<ErrorOutlineIcon />}
              onClick={() => handleClose(false)}
            >
              HỦY BỎ
            </Button>
            <Button
              startIcon={<CheckCircleIcon />}
              onClick={() => handleClose(true)}
              autoFocus
            >
              XÁC NHẬN
            </Button>
          </DialogActions>
        </MUIDialog>
      );
    }

    return (
      <MUIDialog
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleBackdropClick}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
      </MUIDialog>
    );
  }
);

export default Dialog;
