import {
  List,
  Datagrid,
  EditButton,
  DeleteWithConfirmButton,
  FunctionField,
  TextField,
  DateField,
} from 'react-admin';
import shared from 'shared';

const TypeField = () => {
  return (
    <FunctionField
      render={(record) => {
        const value = shared.Constants.NEWS_TYPE.find(
          (item) => item.id === record.type
        );
        return <span className="block w-[150px]">{value?.name}</span>;
      }}
    />
  );
};

export const NewsList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <DateField locales="vi-VN" label="Ngày tạo" source="createdAt" />
      <TextField label="Tiêu đề" source="title" />
      <TypeField label="Thể loại" />
      <DeleteWithConfirmButton
        confirmTitle="Bạn có muốn xóa dữ liệu này?"
        confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
      />
      <EditButton />
    </Datagrid>
  </List>
);
