import NewspaperIcon from '@mui/icons-material/Newspaper';
import { NewsCreate, NewsEdit } from './CreateEdit';
import { NewsList } from './List';

const NewsResource = {
  list: NewsList,
  edit: NewsEdit,
  create: NewsCreate,
  name: 'news',
  options: {
    label: 'Tin tức',
  },
  icon: NewspaperIcon,
};

export default NewsResource;
