import useSWR from 'swr';
import http from 'providers/HttpProvider';

const fetcher = async (params) => {
  const queries = new URLSearchParams(params);
  const { type, id, remotely } = Object.fromEntries(queries.entries());
  const { data } = await http.get('/common/address', {
    params: {
      type,
      id,
      remotely,
    },
  });
  return data;
};

const useAddress = (type, id, remotely = false) => {
  const { data, ...rest } = useSWR(
    `type=${type}&id=${id}&remotely=${remotely}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    ...data,
    ...rest,
  };
};

export default useAddress;
