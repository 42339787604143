import React, { useMemo, useRef } from 'react';
import shared from 'shared';
import dayjs from 'dayjs';
import { TopToolbar, useListContext } from 'react-admin';
import writeXlsxFile from 'write-excel-file';
import { Box, Button } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import http from 'providers/HttpProvider';
import Search from 'components/FormControls/Search';
import GlobalLoading from 'components/LoadingProgress';

const DEFAULT_FILTER = {
  phone: '',
};

const Filters = () => {
  const loadingRef = useRef();
  const { filterValues, setFilters } = useListContext();
  const defaultValues = useMemo(() => {
    return filterValues;
  }, [filterValues]);

  const form = useForm({ defaultValues });

  const onSubmit = (values) => {
    setFilters(values);
  };

  const handleReset = () => {
    form.reset(DEFAULT_FILTER);
    setFilters(DEFAULT_FILTER);
  };

  const handleExport = async () => {
    try {
      loadingRef.current?.open();
      const { data } = await http.get('/booking', {
        params: {
          limit: Number.MAX_SAFE_INTEGER,
          page: 1,
          order: 'createdAt,desc',
        },
      });
      const objects = data?.data ?? [];
      const schema = [
        {
          column: 'Họ và tên',
          type: String,
          value: (record) => record.name,
          width: 20,
        },
        {
          column: 'Số điện thoại',
          type: String,
          value: (record) => record.phone,
          width: 20,
        },
        {
          column: 'Trạng thái',
          type: String,
          value: (record) =>
            Object.values(shared.Constants.BOOKING_STATUS).find(
              (v) => v.VALUE === record?.status
            )?.TEXT,
          width: 20,
        },
        {
          column: 'Nhân viên thị trường',
          type: String,
          value: (record) => record?.assignedUser?.name,
          width: 20,
        },
        {
          column: 'Mã phòng',
          type: String,
          value: (record) => record.product ?? '',
          width: 20,
        },
        {
          column: 'Thời gian xem phòng',
          type: String,
          value: (record) =>
            record.expectedDate &&
            dayjs(record.expectedDate, 'DD-MM-YYYY HH:mm').isValid()
              ? dayjs(record.expectedDate, 'DD-MM-YYYY HH:mm').format(
                  'DD-MM-YYYY'
                )
              : '',
          width: 20,
        },
        {
          column: 'Khoảng giá',
          type: String,
          value: (record) => {
            if (record.price) {
              return record.price
                .split(',')
                .map((item) => shared.currencyFormat(parseInt(item)))
                .join(' - ');
            }
            return '';
          },
          width: 30,
        },
        {
          column: 'Mã CTV',
          type: String,
          value: (record) => record.affiliate ?? '',
          width: 20,
        },
      ];

      await writeXlsxFile(objects, {
        fileName: `Tu_Van_${dayjs().format('DD_MM_YYYY')}.xlsx`,
        schema,
      });
    } catch (error) {
      console.log(error);
    } finally {
      loadingRef.current?.close();
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center" mb={1}>
            <div className="flex-1">
              <Search source="phone" label="Tìm kiếm theo số điện thoại" />
            </div>

            <div className="flex items-center">
              <Button
                style={{ marginLeft: 20 }}
                variant="outlined"
                color="primary"
                type="submit"
              >
                Lọc
              </Button>
              <Button
                onClick={handleReset}
                variant="outlined"
                style={{ marginLeft: 10 }}
                color="error"
                type="button"
              >
                Xóa
              </Button>

              <Button
                onClick={handleExport}
                style={{ marginLeft: 10 }}
                variant="contained"
                color="primary"
                type="button"
              >
                Xuất dữ liệu
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
      <GlobalLoading ref={loadingRef} />
    </>
  );
};

const Filter = ({ permissions }) => {
  return (
    <Box width="100%">
      <TopToolbar />
      {permissions === shared.Constants.ROLE_TYPES.ADMIN && <Filters />}
    </Box>
  );
};

export default Filter;
