import { useMemo } from 'react';
import shared from 'shared';

const Expand = ({ record }) => {
  const {
    product,
    note,
    expectedDate,
    affiliate,
    comment,
    assignedUser,
    appointmentDate,
    location,
    contact,
  } = record;
  const price = useMemo(() => {
    if (record.price) {
      return record.price
        .split(',')
        .map((item) => shared.currencyFormat(parseInt(item)))
        .join(' - ');
    }
    return '';
  }, [record.price]);

  return (
    <div className="bg-white p-3">
      <div className="grid grid-cols-5 gap-4">
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Căn hộ quan tâm</p>
          <p>{product}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Lời nhắn</p>
          <p>{note}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Khoảng giá</p>
          {price && <p>{price}</p>}
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Thời gian hẹn xem phòng</p>
          <p>{expectedDate}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Mã CTV giới thiệu</p>
          <p>{affiliate}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Bình luận</p>
          <p>{comment}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Nhân viên thị trường</p>
          {assignedUser?.name && <p>{assignedUser?.name}</p>}
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Thời gian hẹn gặp khách</p>
          <p>{appointmentDate}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Địa điểm</p>
          <p>{location}</p>
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-semibold">Thông tin liên hệ</p>
          <p>{contact}</p>
        </div>
      </div>
    </div>
  );
};

export default Expand;
