import axios from 'axios';
import shared from 'shared';
import { removeLocalStorageItems } from './AuthProvider';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const handleRefreshToken = async (error) => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      const { data } = await http.post('/authentication/refresh/admin', {
        refreshToken,
      });
      localStorage.setItem('token', data.data.accessToken);
      localStorage.setItem('refreshToken', data.data.refreshToken);
      return http({
        ...error.config,
        data: error.config.data ? JSON.parse(error.config.data) : {},
      });
    }
    removeLocalStorageItems();
    window.location.reload();
    return Promise.reject(error);
  } catch (err) {
    removeLocalStorageItems();
    window.location.reload();
    return Promise.reject(error);
  }
};

// Add a request interceptor
http.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token} ${shared.Constants.ROLE_TYPES.ADMIN}`,
      };
    }
    // Do something before request is sent
    return {
      ...config,
    };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 403) {
      return await handleRefreshToken(error);
    }
    if (error.response && error.response.data && error.response.data.message) {
      return Promise.reject({
        message: error.response.data.message,
        status: error.response.status,
      });
    }
    return Promise.reject(error);
  }
);

export default http;
