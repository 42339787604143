import {
  List,
  Datagrid,
  TextField,
  DeleteWithConfirmButton,
  FunctionField,
  useGetIdentity,
} from 'react-admin';
import shared from 'shared';

const DeleteUserButton = () => {
  const { identity } = useGetIdentity();
  const canDeleted = identity?.role !== shared.Constants.ROLE_TYPES.LIMITED;

  return (
    <FunctionField
      render={(record) => {
        if (canDeleted && identity?.id !== record.id) {
          return (
            <DeleteWithConfirmButton
              confirmTitle="Bạn có muốn xóa dữ liệu này?"
              confirmContent="Bạn sẽ không thể khôi phục dữ liệu sau khi xóa"
            />
          );
        }
        return null;
      }}
    />
  );
};

const RoleField = () => {
  return (
    <FunctionField
      render={(record) => {
        let displayText = null;
        switch (record?.role) {
          case shared.Constants.ROLE_TYPES.ADMIN:
            displayText = 'Quản trị viên';
            break;
          case shared.Constants.ROLE_TYPES.EMPLOYEE:
            displayText = 'Nhân viên thị trường';
            break;
          default:
            break;
        }
        return <span>{displayText}</span>;
      }}
    />
  );
};

export const AdminList = () => {
  const { identity } = useGetIdentity();

  return (
    <List
      hasCreate={identity?.role !== shared.Constants.ROLE_TYPES.LIMITED}
      exporter={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Tên người dùng" source="name" />
        <TextField label="Email" source="email" />
        <RoleField label="Quyền truy cập" />
        <DeleteUserButton />
      </Datagrid>
    </List>
  );
};
