import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  SelectInput,
} from 'react-admin';
import React from 'react';
import shared from 'shared';

const DEFAULT_PASSWORD = `ANHOME${new Date().getFullYear()}$`;

const transform = (values) => {
  return {
    ...values,
    password: DEFAULT_PASSWORD,
  };
};

const Form = () => {
  return (
    <SimpleForm>
      <TextInput label="Tên" source="name" validate={[required()]} fullWidth />
      <TextInput
        label="Email"
        source="email"
        validate={[required(), email()]}
        fullWidth
      />
      <SelectInput
        defaultValue={shared.Constants.ROLE_TYPES.LIMITED}
        validate={[required()]}
        fullWidth
        label="Vai trò"
        source="role"
        choices={[
          { id: shared.Constants.ROLE_TYPES.ADMIN, name: 'Quản trị viên' },
          {
            id: shared.Constants.ROLE_TYPES.EMPLOYEE,
            name: 'Nhân viên thị trường',
          },
        ]}
      />
      <p style={{ margin: 0 }}>
        <span style={{ fontStyle: 'italic' }}>Mật khẩu mặc định: </span>
        <strong>{DEFAULT_PASSWORD}</strong>
      </p>
    </SimpleForm>
  );
};

export const AdminCreate = () => {
  return (
    <Create transform={transform} redirect="list">
      <Form />
    </Create>
  );
};

export const AdminEdit = () => {
  return (
    <Edit
      transform={transform}
      mutationMode="pessimistic"
      redirect="list"
      title="Chỉnh sửa"
    >
      <Form />
    </Edit>
  );
};
