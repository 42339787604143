import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  useEditContext,
} from 'react-admin';
import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import shared from 'shared';
import { DEFAULT_FILTERS } from 'components/SharedFilters';
import SelectProducts from './SelectProducts';

export const DEFAULT_FILTER_VALUES = {
  ...DEFAULT_FILTERS,
  productCode: '',
  productStatus: shared.Constants.PRODUCT_STATUS.AVAILABLE.VALUE,
};

const handleTransform = (values) => {
  const {
    account,
    bank,
    code,
    email,
    id,
    idenfier,
    name,
    phone,
    status,
    products,
  } = values;
  const payload = {
    account,
    bank,
    code,
    email,
    id,
    idenfier,
    name,
    phone,
    status,
    products,
  };
  return payload;
};

const Form = ({ defaultValues }) => {
  return (
    <SimpleForm defaultValues={defaultValues}>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <TextInput
            InputProps={{ readOnly: true }}
            label="Tên"
            source="name"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            InputProps={{ readOnly: true }}
            label="Mã CTV"
            source="code"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            InputProps={{ readOnly: true }}
            label="CCCD"
            source="idenfier"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            InputProps={{ readOnly: true }}
            label="Số điện thoại"
            source="phone"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            InputProps={{ readOnly: true }}
            label="Email"
            source="email"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            InputProps={{ readOnly: true }}
            label="Tài khoản ngân hàng"
            source="bankInformation"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <SelectProducts />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const EditForm = () => {
  const { record } = useEditContext();
  const defaultValues = useMemo(() => {
    const values = {
      ...DEFAULT_FILTER_VALUES,
      ...record,
      bankInformation:
        record.bank && record.account ? `${record.bank} ${record.account}` : '',
    };
    return values;
  }, [record]);

  return <Form defaultValues={defaultValues} />;
};

export const AffiliatCreate = () => {
  return (
    <Create redirect="list">
      <Form />
    </Create>
  );
};

export const AffiliatEdit = () => {
  return (
    <>
      <Edit
        transform={handleTransform}
        mutationMode="pessimistic"
        redirect="list"
        title="Chỉnh sửa"
      >
        <EditForm />
      </Edit>
    </>
  );
};
