import React, { useEffect, useMemo } from 'react';
import {
  Toolbar,
  SaveButton,
  Loading,
  Empty,
  TextInput,
  required,
  number,
  email,
  TabbedForm,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectArrayInput,
  RadioButtonGroupInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import { useFormContext } from 'react-hook-form';
import { useGetSetting, useSubmitSetting } from 'hooks/useSetting';
import useAddress from 'hooks/useAddress';
import FileProvider from 'providers/FileProvider';
import { LoadingProgress } from 'components/LoadingProgress';
import ImagePicker from 'components/FormControls/ImagePicker';

const filterToQuery = (searchText) => ({ code: searchText });

const SettingToolbar = ({ isLoading }) => {
  return (
    <Toolbar>
      <SaveButton
        disabled={isLoading}
        icon={isLoading ? <LoadingProgress /> : <SaveIcon />}
        label="Lưu"
      />
    </Toolbar>
  );
};

const SelectDistrict = () => {
  const { data, isLoading } = useAddress('district', '79', true);

  useEffect(() => {
    window.DISTRICT_LIST = data;
  }, [data]);

  return (
    <SelectArrayInput
      validate={[required()]}
      label="Chọn quận/huyện"
      fullWidth
      optionValue="idDistrict"
      optionText="name"
      isLoading={isLoading}
      source="selectedDistricts"
      choices={data ?? []}
    />
  );
};

const SelectProducts = ({ term, source }) => {
  return (
    <>
      <ReferenceArrayInput
        filter={{ term }}
        reference="products"
        source={source}
      >
        <AutocompleteArrayInput
          disableClearable
          debounce={500}
          filterToQuery={filterToQuery}
          optionText="code"
          label={`Chọn căn hiển thị trang chủ (${
            term === 2 ? 'Dài hạn' : 'Ngắn hạn'
          })`}
        />
      </ReferenceArrayInput>
    </>
  );
};

const BasicInformationForm = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={6}>
        <TextInput
          label="Tên website"
          source="name"
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Số điện thoại"
          source="phone"
          validate={[required(), number()]}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Email"
          source="email"
          validate={[required(), email()]}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Địa chỉ"
          source="address"
          validate={[required()]}
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="Android app" source="android" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextInput label="iOS app" source="ios" fullWidth />
      </Grid>

      <Grid item xs={12}>
        <ImagePicker source="watermark" label="Watermark" />
      </Grid>
      <Grid item xs={12}>
        <RadioButtonGroupInput
          label="Vị trí hiển thị Watermark"
          source="watermark.position"
          choices={[
            { id: 'northeast', name: 'Phía trên bên phải' },
            { id: 'northwest', name: 'Phía trên bên trái' },
            { id: 'southeast', name: 'Phía dưới bên phải' },
            { id: 'southwest', name: 'Phía dưới bên trái' },
            { id: 'center', name: 'Ở giữa trung tâm' },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          multiline
          rows={3}
          label="Giới thiệu"
          source="description"
          validate={[required()]}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const OtherSettingForm = () => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <SelectProducts term={2} source="longProducts" />
      </Grid>

      <Grid item xs={12}>
        <SelectProducts term={1} source="shortProducts" />
      </Grid>

      <Grid item xs={12}>
        <SelectDistrict />
      </Grid>
    </Grid>
  );
};

const SocialNetworkForm = () => {
  const { getValues } = useFormContext();
  const values = getValues();

  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <ArrayInput label="Các đường dẫn Facebook" fullWidth source="facebook">
          <SimpleFormIterator
            disableReordering
            disableAdd={values?.facebook?.length > 1}
            disableClear
            fullWidth
            inline
          >
            <TextInput label="Đường dẫn Facebook" fullWidth source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid item xs={12}>
        <ArrayInput label="Các đường dẫn Zalo" fullWidth source="zalo">
          <SimpleFormIterator
            disableReordering
            disableAdd={values?.zalo?.length > 1}
            disableClear
            fullWidth
            inline
          >
            <TextInput label="Đường dẫn Zalo" fullWidth source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid item xs={12}>
        <ArrayInput label="Các đường dẫn Youtube" fullWidth source="youtube">
          <SimpleFormIterator
            disableReordering
            disableAdd={values?.youtube?.length > 1}
            disableClear
            fullWidth
            inline
          >
            <TextInput label="Đường dẫn Youtube" fullWidth source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid item xs={12}>
        <ArrayInput label="Các đường dẫn Tiktok" fullWidth source="tiktok">
          <SimpleFormIterator
            disableReordering
            disableAdd={values?.tiktok?.length > 1}
            disableClear
            fullWidth
            inline
          >
            <TextInput label="Đường dẫn Tiktok" fullWidth source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>

      <Grid item xs={12}>
        <ArrayInput
          label="Các đường dẫn Instagram"
          fullWidth
          source="instagram"
        >
          <SimpleFormIterator
            disableReordering
            disableAdd={values?.instagram?.length > 1}
            disableClear
            fullWidth
            inline
          >
            <TextInput label="Đường dẫn Instagram" fullWidth source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};

export const SettingList = () => {
  const { data, isLoading } = useGetSetting();
  const { trigger, isMutating } = useSubmitSetting();
  const defaultValues = useMemo(() => {
    if (!data) {
      return {};
    }
    return {
      ...data,
      selectedDistricts: data.districts?.map((item) => item.idDistrict) ?? [],
    };
  }, [data]);

  const handleSubmit = async (values) => {
    try {
      values.districts = values.selectedDistricts.map((item) => {
        return {
          idProvince: '79',
          idDistrict: item,
          name: window.DISTRICT_LIST.find((v) => v.idDistrict === item)?.name,
        };
      });
      if (values.watermark && values.watermark.rawFile) {
        values.watermark = await FileProvider.uploadImage(
          values.watermark.rawFile,
          { watermark: false }
        );
      }
      await trigger({ values });
    } catch (error) {
      console.log(error);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return <Empty />;
  }

  return (
    <Card style={{ marginTop: 50 }}>
      <TabbedForm
        toolbar={<SettingToolbar isLoading={isMutating} />}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        <TabbedForm.Tab label="Cài đặt chung">
          <BasicInformationForm />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Cài đặt MXH">
          <SocialNetworkForm />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Cài đặt khác">
          <OtherSettingForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Card>
  );
};
